import React from "react";
import "./Row.scss";
export default function Row({ note, name, time }) {
  return note ? (
    <li className="row">
      <div>
        <span>{name}</span>
        <p className="note">{note}</p>
      </div>
      <span>{time}</span>
    </li>
  ) : (
    <li className="row">
      <span>{name}</span>
      <span>{time}</span>
    </li>
  );
}
