import React, { useEffect, useState } from "react";
import * as dateFNS from "date-fns";

import Row from "../row/Row";

import "./Chart.scss";

export default function Chart() {
  const [timetable, setTimetable] = useState(null);

  useEffect(() => {
    fetchURL();
  }, []);

  async function fetchURL() {
    const URL = `https://observed.syeef.workers.dev/`;
    const urlResponse = await fetch(URL);
    const urlResponseJSON = await urlResponse.json();
    setTimetable(urlResponseJSON);
  }

  const date = timetable
    ? dateFNS.parse(timetable.date, "yyyy-MM-dd", new Date())
    : "";

  const formattedDate = date ? dateFNS.format(date, "eeee, LLLL do") : "";

  return (
    <>
      <div className="chart">
        <div className="details">
          <h2>{formattedDate}</h2>
        </div>
        <ul className="list">
          {timetable && timetable["imsak"] ? (
            <Row
              name="Imsak"
              time={timetable ? timetable["imsak"] : "loading…"}
            ></Row>
          ) : (
            <></>
          )}

          <Row
            name="Fajr"
            time={timetable ? timetable["fajr"] : "loading…"}
          ></Row>
          <Row
            name="Sunrise"
            time={timetable ? timetable["sunrise"] : "loading…"}
          ></Row>
          <Row
            name="Dhuhr"
            time={timetable ? timetable["dhuhr"] : "loading…"}
          ></Row>
          <Row
            name="^Asr"
            time={timetable ? timetable["asr"] : "loading…"}
          ></Row>
          <Row
            name="Maghrib"
            note="As a precaution, the time displayed here is a few minutes later than the actual time that Sunset is expected to occur. Therefore, pray ^Asr much sooner than this, as otherwise its' time would have most certainly already ended."
            time={timetable ? timetable["maghrib"] : "loading…"}
          ></Row>
          <Row
            name="^Isha"
            time={timetable ? timetable["isha"] : "loading…"}
          ></Row>
        </ul>
      </div>
    </>
  );
}
